import React from 'react';
import { GoogleMap, LoadScript, MarkerF } from '@react-google-maps/api';

const MapComponent = ({ lat, lng }) => {
    const mapStyles = {
        height: "200px",
        width: "100%"
    };

    const defaultCenter = {
        lat: lat,
        lng: lng
    };

    return (
        <LoadScript googleMapsApiKey="AIzaSyDtsbmogFMv_yt57257ekzFSZflhyesxe4">
            <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={15}
                center={defaultCenter}
            >
                <MarkerF position={defaultCenter} />
            </GoogleMap>
        </LoadScript>
    );
}

export default MapComponent;
