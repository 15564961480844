import React from 'react';
import './css/OpeningPage.css'; // File CSS untuk gaya khusus halaman Quotes
import "animate.css"
import {useInvitation} from "../../../invitationContext";

const OpeningPage = () => {

    const { data, guest } = useInvitation();

    if (!data || !guest) {
        return <p>Loading...</p>;
    }

    const { opening } = data;

    return (
        <div className="quotes-page">
            <div className="frame">
                <img
                    src={`${process.env.PUBLIC_URL}/tl.webp`}
                    alt="frame"
                    className="frame-tl animate__animated animate__fadeInTopLeft animate__slow"
                />
                <img
                    src={`${process.env.PUBLIC_URL}/tr.webp`}
                    alt="frame"
                    className="frame-tr animate__animated animate__fadeInTopRight animate__slower"
                />
                <img
                    src={`${process.env.PUBLIC_URL}/bl.webp`}
                    alt="frame"
                    className="frame-bl animate__animated animate__fadeInBottomLeft animate__slower"
                />
                <img
                    src={`${process.env.PUBLIC_URL}/br.webp`}
                    alt="frame"
                    className="frame-br animate__animated animate__fadeInBottomRight animate__slower"
                />
            </div>
            {/*Buatkan di Sini*/}
            <div className="opening-content">
                <p className="invitation-title animate__animated animate__fadeInDown animate__slower">Wedding
                    Invitation</p>
                <div className="photo-container animate__animated animate__fadeInDown animate__slower">
                    <img
                        src={`${process.env.PUBLIC_URL}/tqw-1.JPG`} // Ganti dengan URL foto Anda
                        alt="Taqwa & Lilis"
                        className="couple-photo"
                    />
                </div>
                <h1 className="couple-names animate__animated animate__fadeInDown animate__slower">Taqwa & Lilis</h1>
                <p className="invitation-to animate__animated animate__fadeInUp animate__slower">Kepada Yth</p>
                <p className="guest-tittle animate__animated animate__fadeInUp animate__slower">Bapak/Ibu/Saudara/i</p>
                <p className="guest-name animate__animated animate__fadeInUp animate__slower">{guest.nama}</p>
            </div>
        </div>
    );
};

export default OpeningPage;
