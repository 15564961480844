import React from 'react';
import './css/MempelaiPage.css'; // File CSS untuk gaya khusus halaman Quotes
import "animate.css"

const MempelaiPage = () => {
    return (
        <div className="quotes-page">
            <div className="frame">
                <img
                    src={`${process.env.PUBLIC_URL}/tl.webp`}
                    alt="frame"
                    className="frame-tl animate__animated animate__fadeInTopLeft animate__slow"
                />
                <img
                    src={`${process.env.PUBLIC_URL}/tr.webp`}
                    alt="frame"
                    className="frame-tr animate__animated animate__fadeInTopRight animate__slower"
                />
                <img
                    src={`${process.env.PUBLIC_URL}/bl.webp`}
                    alt="frame"
                    className="frame-bl animate__animated animate__fadeInBottomLeft animate__slower"
                />
                <img
                    src={`${process.env.PUBLIC_URL}/br.webp`}
                    alt="frame"
                    className="frame-br animate__animated animate__fadeInBottomRight animate__slower"
                />
            </div>
            <div className="mempelai-container animate__animated animate__zoomIn animate__slower">
                <p className="greeting-text text-center animate__animated animate__fadeInDown animate__slower">Assalamu'alaikum Wr. Wb.</p>
                <p className="invite-text text-center animate__animated animate__fadeInDown animate__slower">
                    Tanpa mengurangi rasa hormat Kami bermaksud mengundang Bapak/Ibu/Saudara/i pada acara pernikahan kami
                </p>
                <div className="mempelai">
                    <div className="mempelai-item animate__animated animate__fadeInUp animate__slower">
                        <img src="https://firebasestorage.googleapis.com/v0/b/inviteku-app.appspot.com/o/photos%2Ftaqwa-self-square.png?alt=media&token=a76ae32a-61a2-4158-8c3d-f05a22d71be1" alt="Taqwa" className="mempelai-photo"/>
                        <p className="mempelai-name">Taqwa Rahmansyah</p>
                        <p className="mempelai-info">Putra dari Bapak Suyoto Hadi (Alm.) & Ibu Lamirah (Alm.) </p>
                        {/*<p className="mempelai-ig">@instagram</p>*/}
                    </div>
                    <div className="mempelai-item animate__animated animate__fadeInUp animate__slower">
                        <img src="https://firebasestorage.googleapis.com/v0/b/inviteku-app.appspot.com/o/photos%2Flilis-self-square.png?alt=media&token=4740bcb0-b8e5-469e-bd49-fcb88f74379d" alt="Lilis" className="mempelai-photo"/>
                        <p className="mempelai-name">Lilis Nur Aini</p>
                        <p className="mempelai-info">Putri dari Bapak Masruri & Ibu Siti Samsiyah</p>
                        {/*<p className="mempelai-ig">@instagram</p>*/}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MempelaiPage;
