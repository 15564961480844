import React from 'react';
import './css/AkadPage.css'; // File CSS untuk gaya khusus halaman Quotes
import "animate.css"

const AkadPage = () => {
    return (
        <div className="akad-page">
            <div className="frame">
                <img
                    src={`${process.env.PUBLIC_URL}/tl.webp`}
                    alt="frame"
                    className="frame-tl animate__animated animate__fadeInTopLeft animate__slow"
                />
                <img
                    src={`${process.env.PUBLIC_URL}/tr.webp`}
                    alt="frame"
                    className="frame-tr animate__animated animate__fadeInTopRight animate__slower"
                />
                <img
                    src={`${process.env.PUBLIC_URL}/bl.webp`}
                    alt="frame"
                    className="frame-bl animate__animated animate__fadeInBottomLeft animate__slower"
                />
                <img
                    src={`${process.env.PUBLIC_URL}/br.webp`}
                    alt="frame"
                    className="frame-br animate__animated animate__fadeInBottomRight animate__slower"
                />
            </div>
            <div className="akad-container animate__animated animate__fadeIn animate__slower">
                <h1 className="akad-title animate__animated animate__fadeInDown animate__slower">Akad Nikah & Resepsi</h1>
                <div className="akad-date animate__animated animate__zoomIn animate__slower">
                    <p className="akad-day">Minggu</p>
                    <div className="akad-day-number-container">
                        <hr className="line-above"/>
                        <span className="akad-day-number">23</span>
                        <hr className="line-below"/>
                    </div>
                    <p className="akad-month-year">Juni 2024</p>
                </div>
                <p className="akad-time animate__animated animate__fadeInUp animate__slower">Akad Nikah Pukul 08.00 WIB, Resepsi Pukul 10.00 WIB - Selesai</p>
                <p className="akad-location-name animate__animated animate__fadeInUp animate__slower">Dusun Mojoranu, Desa Ngepeh</p>
                <p className="akad-location-address animate__animated animate__fadeInUp animate__slower">Kecamatan Loceret, Nganjuk
                </p>
            </div>
        </div>
    );
};

export default AkadPage;
