// InvitationContext.js
import React, { createContext, useContext, useState } from 'react';

const InvitationContext = createContext();

export const useInvitation = () => {
    return useContext(InvitationContext);
};

export const InvitationProvider = ({ children }) => {
    const [data, setData] = useState(null);
    const [guest, setGuest] = useState(null);

    return (
        <InvitationContext.Provider value={{ data, setData, guest, setGuest }}>
            {children}
        </InvitationContext.Provider>
    );
};
