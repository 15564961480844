import React, { useEffect, useState, useRef } from 'react';
import OpeningPage from "./components/OpeningPage";
import QuotesPage from "./components/QuotesPage";
import MempelaiPage from "./components/MempelaiPage";
import AkadPage from "./components/AkadPage";
import ResepsiPage from "./components/ResepsiPage";
import MapsPage from "./components/MapsPage";
import GalleryPage from "./components/GalleryPage";
import GiftPage from "./components/GiftPage";
import { Container } from "react-bootstrap";
import Navbar from "./components/Navbar";
import './MainPage.css';
import FirstPage from "./components/FirstPage";

const MainPage = () => {
  const [activePage, setActivePage] = useState('');
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const [isMusicPlaying, setIsMusicPlaying] = useState(false);

  const audioRef = useRef(null);

  const pages = ['Opening', 'Quotes', 'Mempelai', 'Akad', 'Resepsi', 'Maps', 'Gallery'];

  useEffect(() => {
    const basePath = '/taqwa-lilis';
    const path = window.location.pathname.replace(basePath, '');
    const initialPage = path === '/' ? 'NotFound' : path.replace('/', '');
    // setActivePage(initialPage);

    const audio = audioRef.current;
    if (audio) {
      if (isMusicPlaying) {
        audio.play();
      } else {
        audio.pause();
      }
    }

    const handleWheel = (event) => {
      if (event.deltaY > 0) {
        // Scroll down
        setActivePage((prevPage) => {
          const currentIndex = pages.indexOf(prevPage);
          return pages[Math.min(currentIndex + 1, pages.length - 1)];
        });
      } else {
        // Scroll up
        setActivePage((prevPage) => {
          const currentIndex = pages.indexOf(prevPage);
          return pages[Math.max(currentIndex - 1, 0)];
        });
      }
    };

    const handleTouchStart = (e) => {
      setTouchStart(e.targetTouches[0].clientY);
    };

    const handleTouchMove = (e) => {
      setTouchEnd(e.targetTouches[0].clientY);
    };

    const handleTouchEnd = () => {
      if (touchStart - touchEnd > 150) {
        // Swipe up
        setActivePage((prevPage) => {
          const currentIndex = pages.indexOf(prevPage);
          return pages[Math.min(currentIndex + 1, pages.length - 1)];
        });
      }

      if (touchEnd - touchStart > 150) {
        // Swipe down
        setActivePage((prevPage) => {
          const currentIndex = pages.indexOf(prevPage);
          return pages[Math.max(currentIndex - 1, 0)];
        });
      }
    };

    window.addEventListener('wheel', handleWheel);
    window.addEventListener('touchstart', handleTouchStart);
    window.addEventListener('touchmove', handleTouchMove);
    window.addEventListener('touchend', handleTouchEnd);

    return () => {
      window.removeEventListener('wheel', handleWheel);
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchmove', handleTouchMove);
      window.removeEventListener('touchend', handleTouchEnd);
    };
  }, [touchStart, touchEnd, isMusicPlaying]);

  const toggleMusic = () => {
    setIsMusicPlaying(!isMusicPlaying);
  };

  const renderContent = () => {
    switch (activePage) {
      case 'Opening':
        return <OpeningPage />;
      case 'Quotes':
        return <QuotesPage />;
      case 'Mempelai':
        return <MempelaiPage />;
      case 'Akad':
        return <AkadPage />;
      case 'Resepsi':
        return <ResepsiPage />;
      case 'Maps':
        return <MapsPage />;
      case 'Gallery':
        return <GalleryPage />;
      case 'Gift':
        return <GiftPage />;
      default:
        return <FirstPage setActivePage={setActivePage} setPlayingMusic={setIsMusicPlaying} />;
    }
  };

  const renderNavbar = () => {
    switch (activePage) {
      case '':
        return null;
      default:
        return <Navbar activePage={activePage} setActivePage={setActivePage} />;
    }
  };

  return (
      <div className="app-background">
        <Container className="app-container">
          {renderContent()}
        </Container>
        <div className="button-music-container">
          <button className="music-toggle" onClick={toggleMusic}>
            {isMusicPlaying ? (
                <img className="img-toggle" src={`${process.env.PUBLIC_URL}/icons/volume-cross.svg`} alt="Pause Music" />
            ) : (
                <img className="img-toggle" src={`${process.env.PUBLIC_URL}/icons/volume-high.svg`} alt="Play Music" />
            )}
          </button>
        </div>
        {renderNavbar()}
        <audio id="background-music" ref={audioRef} autoPlay loop>
          <source src="https://firebasestorage.googleapis.com/v0/b/inviteku-app.appspot.com/o/music%2Fangin-cut-tqw.mp3?alt=media&token=f55e821a-2b58-4d8c-9494-5eef5f67a403" type="audio/mp3" />
          Your browser does not support the audio element.
        </audio>
      </div>
  );
};

export default MainPage;
