import React, { useEffect, useRef } from "react";

const Navbar = ({ activePage, setActivePage }) => {
    const navbarRef = useRef(null);

    useEffect(() => {
        const activeItem = navbarRef.current.querySelector('.active');
        if (activeItem) {
            const navbarWidth = navbarRef.current.offsetWidth;
            const itemWidth = activeItem.offsetWidth;
            const itemOffsetLeft = activeItem.offsetLeft;

            const scrollPosition = itemOffsetLeft - (navbarWidth / 2) + (itemWidth / 2);
            navbarRef.current.scrollTo({
                left: scrollPosition,
                behavior: 'smooth'
            });
        }
    }, [activePage]);

    return (
        <ul className="custom-navbar" ref={navbarRef}>
            <li className={`nav-item ${activePage === 'Opening' ? 'active' : ''}`}>
                <a href="#" className="nav-link" onClick={() => setActivePage('Opening')}>
                    <img
                        className="img-icon"
                        src={`${process.env.PUBLIC_URL}/icons/home.svg`}
                        alt="frame"
                    />
                    <span className="nav-title">Opening</span>
                </a>
            </li>
            <li className={`nav-item ${activePage === 'Quotes' ? 'active' : ''}`}>
                <a href="#" className="nav-link" onClick={() => setActivePage('Quotes')}>
                    <img
                        className="img-icon"
                        src={`${process.env.PUBLIC_URL}/icons/messages.svg`}
                        alt="frame"
                    />
                    <span className="nav-title">Quotes</span>
                </a>
            </li>
            <li className={`nav-item ${activePage === 'Mempelai' ? 'active' : ''}`}>
                <a href="#" className="nav-link" onClick={() => setActivePage('Mempelai')}>
                    <img
                        className="img-icon"
                        src={`${process.env.PUBLIC_URL}/icons/user_profile.svg`}
                        alt="frame"
                    />
                    <span className="nav-title">Mempelai</span>
                </a>
            </li>
            <li className={`nav-item ${activePage === 'Akad' ? 'active' : ''}`}>
                <a href="#" className="nav-link" onClick={() => setActivePage('Akad')}>
                    <img
                        className="img-icon"
                        src={`${process.env.PUBLIC_URL}/icons/calender.svg`}
                        alt="frame"
                    />
                    <span className="nav-title">Akad</span>
                </a>
            </li>
            <li className={`nav-item ${activePage === 'Resepsi' ? 'active' : ''}`}>
                <a href="#" className="nav-link" onClick={() => setActivePage('Resepsi')}>
                    <img
                        className="img-icon"
                        src={`${process.env.PUBLIC_URL}/icons/calender.svg`}
                        alt="frame"
                    />
                    <span className="nav-title">Resepsi</span>
                </a>
            </li>
            <li className={`nav-item ${activePage === 'Maps' ? 'active' : ''}`}>
                <a href="#" className="nav-link" onClick={() => setActivePage('Maps')}>
                    <img
                        className="img-icon"
                        src={`${process.env.PUBLIC_URL}/icons/location.svg`}
                        alt="frame"
                    />
                    <span className="nav-title">Maps</span>
                </a>
            </li>
            <li className={`nav-item ${activePage === 'Gallery' ? 'active' : ''}`}>
                <a href="#" className="nav-link" onClick={() => setActivePage('Gallery')}>
                    <img
                        className="img-icon"
                        src={`${process.env.PUBLIC_URL}/icons/gallery.svg`}
                        alt="frame"
                    />
                    <span className="nav-title">Gallery</span>
                </a>
            </li>
            <li className={`nav-item ${activePage === 'Gift' ? 'active' : ''}`}>
                <a href="#" className="nav-link" onClick={() => setActivePage('Gift')}>
                    <img
                        className="img-icon"
                        src={`${process.env.PUBLIC_URL}/icons/send_gift.svg`}
                        alt="frame"
                    />
                    <span className="nav-title">Gift</span>
                </a>
            </li>
        </ul>
    );
};

export default Navbar;
