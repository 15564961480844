import React from 'react';
import './css/FirstPage.css'; // File CSS untuk gaya khusus halaman Quotes
import "animate.css"
import {useInvitation} from "../../../invitationContext";

const FirstPage = ({ setActivePage, setPlayingMusic, onOpenInvitation}) => {

    const handleOpenInvitation = () => {
        setActivePage('Quotes');
        setPlayingMusic(true);
        enterFullScreen();
    };

    const { data, guest } = useInvitation();

    if (!data || !guest) {
        return <p>Loading...</p>;
    }

    // Fungsi untuk memasukkan elemen ke mode layar penuh
    const enterFullScreen = () => {
        const element = document.documentElement;
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.webkitRequestFullscreen) { /* Safari */
            element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) { /* IE11 */
            element.msRequestFullscreen();
        }else if (element.mozRequestFullScreen) { // Firefox
            element.mozRequestFullScreen();
        }
    };

    const { opening } = data;

    return (
        <div className="first-page">
            <div className="frame">
                <img
                    src={`${process.env.PUBLIC_URL}/tl.webp`}
                    alt="frame"
                    className="frame-tl animate__animated animate__fadeInTopLeft animate__slow"
                />
                <img
                    src={`${process.env.PUBLIC_URL}/tr.webp`}
                    alt="frame"
                    className="frame-tr animate__animated animate__fadeInTopRight animate__slower"
                />
                <img
                    src={`${process.env.PUBLIC_URL}/bl.webp`}
                    alt="frame"
                    className="frame-bl animate__animated animate__fadeInBottomLeft animate__slower"
                />
                <img
                    src={`${process.env.PUBLIC_URL}/br.webp`}
                    alt="frame"
                    className="frame-br animate__animated animate__fadeInBottomRight animate__slower"
                />
            </div>
            {/*Buatkan di Sini*/}
            <div className="first-page-content">
                <p className="invitation-title animate__animated animate__fadeInDown animate__slower">Wedding
                    Invitation</p>
                <div className="photo-container animate__animated animate__fadeInDown animate__slower">
                    <img
                        src={`${process.env.PUBLIC_URL}/tqw-1.JPG`} // Ganti dengan URL foto Anda
                        alt="Taqwa & Lilis"
                        className="couple-photo"
                    />
                </div>
                <h1 className="couple-names animate__animated animate__fadeInDown animate__slower">Taqwa & Lilis</h1>
                <p className="invitation-to animate__animated animate__fadeInUp animate__slower">Kepada Yth</p>
                <p className="guest-tittle animate__animated animate__fadeInUp animate__slower">Bapak/Ibu/Saudara/i</p>
                <p className="guest-name animate__animated animate__fadeInUp animate__slower">{guest.nama}</p>
                <div className="button-container animate__animated animate__fadeInUp animate__slower">
                    <button className="open-invitation-btn" onClick={handleOpenInvitation}>Open Invitation</button>
                </div>
            </div>
        </div>
    );
};

export default FirstPage;
