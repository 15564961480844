import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useParams, Navigate, useSearchParams } from 'react-router-dom';
import './App.css';
import Template from "./template/template-1/MainPage";
import { InvitationProvider, useInvitation } from './invitationContext'

// Komponen untuk menangani rute /und/:key
const Invitation = () => {
    const { key } = useParams();
    const [searchParams] = useSearchParams();
    const to = searchParams.get('to');
    const { setData, setGuest } = useInvitation();
    const [template, setTemplate] = useState(null);
    const [notFound, setNotFound] = useState(false);

    const baseUrl = "https://server.inviteku.id/api/v1/"

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch undangan data
                const responseUndangan = await fetch(baseUrl + `undangan/${key}`, {
                    headers: {
                        'x-api-key': '12345678'
                    }
                });
                const resultUndangan = await responseUndangan.json();
                if (resultUndangan.code === 200) {
                    setTemplate(resultUndangan.data.template);
                    setData(resultUndangan.data);

                    // Fetch tamu data if undangan is found
                    const responseTamu = await fetch(baseUrl+`tamu/${to}`, {
                        headers: {
                            'x-api-key': '12345678'
                        }
                    });
                    const resultTamu = await responseTamu.json();
                    if (resultTamu.code === 200) {
                        setGuest(resultTamu.data);
                    } else {
                        setNotFound(true);
                    }
                } else {
                    setNotFound(true);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setNotFound(true);
            }
        };

        fetchData();
    }, [key, to, setData]);

    if (notFound) {
        return <Navigate to="/404" />;
    }

    const renderScreen = () => {
        switch (template) {
            case 'template-1':
                return <Template />;
            // Tambahkan case untuk template lainnya di sini
            default:
                return null;
        }
    };

    return (
        <div>
            {template ? renderScreen() : <p>Loading...</p>}
        </div>
    );
};

// Komponen untuk halaman 404
const NotFound = () => {
    return <h1>404 - Not Found</h1>;
};

function App() {
    return (
        <InvitationProvider>
            <Router>
                <Routes>
                    <Route path="/:key" element={<Invitation />} />
                    <Route path="/404" element={<NotFound />} />
                    {/* Tambahkan rute lainnya di sini */}
                </Routes>
            </Router>
        </InvitationProvider>
    );
}

export default App;
