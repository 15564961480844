import React from 'react';
import './css/MapsPage.css'; // File CSS untuk gaya khusus halaman Quotes
import "animate.css"
import MapComponent from './MapComponent';

const MapsPage = () => {
    const openGoogleMaps = (lat, lng) => {
        const url = `https://www.google.com/maps?q=${lat},${lng}`;
        window.open(url, '_blank');
    };

    const openMaps = () => {
        window.open('https://maps.app.goo.gl/gkNC5oyq6K6vY6wQ9', '_blank');
    };

    const openMaps1 = () => {
        window.open('https://maps.app.goo.gl/763DoyjH9vqN5JSb8', '_blank');
    };
    return (
        <div className="maps-page">
            <div className="frame">
                <img
                    src={`${process.env.PUBLIC_URL}/tl.webp`}
                    alt="frame"
                    className="frame-tl animate__animated animate__fadeInTopLeft animate__slow"
                />
                <img
                    src={`${process.env.PUBLIC_URL}/tr.webp`}
                    alt="frame"
                    className="frame-tr animate__animated animate__fadeInTopRight animate__slower"
                />
                <img
                    src={`${process.env.PUBLIC_URL}/bl.webp`}
                    alt="frame"
                    className="frame-bl animate__animated animate__fadeInBottomLeft animate__slower"
                />
                <img
                    src={`${process.env.PUBLIC_URL}/br.webp`}
                    alt="frame"
                    className="frame-br animate__animated animate__fadeInBottomRight animate__slower"
                />
            </div>
            <div className="maps-container animate__animated animate__fadeIn animate__slower">
                <div className="maps-header">
                    <p className="maps-text text-center">Akad & Resepsi</p>
                    <button
                        className="maps-button"
                        onClick={openMaps1}
                    >
                        Buka Maps
                    </button>
                </div>
                <MapComponent lat={-7.670908} lng={111.882644}/>
            </div>

            <div className="maps-container-1 animate__animated animate__fadeIn animate__slower">
                <div className="maps-header">
                    <p className="maps-text text-center">Ngunduh Mantu</p>
                    <button
                        className="maps-button"
                        onClick={openMaps}
                    >
                        Buka Maps
                    </button>
                </div>
                <MapComponent lat={-7.548431} lng={111.730570}/>
            </div>
        </div>
    );
};

export default MapsPage;
