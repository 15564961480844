import React from 'react';
import './css/GiftPage.css';
import "animate.css"

const GiftPage = () => {
    return (
        <div className="gift-page">
            <div className="frame">
                <img
                    src={`${process.env.PUBLIC_URL}/tl.webp`}
                    alt="frame"
                    className="frame-tl animate__animated animate__fadeInTopLeft animate__slow"
                />
                <img
                    src={`${process.env.PUBLIC_URL}/tr.webp`}
                    alt="frame"
                    className="frame-tr animate__animated animate__fadeInTopRight animate__slower"
                />
                <img
                    src={`${process.env.PUBLIC_URL}/bl.webp`}
                    alt="frame"
                    className="frame-bl animate__animated animate__fadeInBottomLeft animate__slower"
                />
                <img
                    src={`${process.env.PUBLIC_URL}/br.webp`}
                    alt="frame"
                    className="frame-br animate__animated animate__fadeInBottomRight animate__slower"
                />
            </div>
            <h2 className="gift-header animate__animated animate__fadeInDown animate__slower">Tanda Kasih</h2>
            <p className="gift-description animate__animated animate__fadeInDown animate__slower">
                Terima kasih telah menambah semangat kegembiraan pernikahan kami dengan kehadiran dan hadiah indah Anda.
            </p>
            {/*<div className="button-container animate__animated animate__fadeIn">*/}
            {/*    <button className="gift-button">🎁 Cashless</button>*/}
            {/*    <button className="gift-button">🎁 Kirim Kado</button>*/}
            {/*</div>*/}
            <div className="account-container animate__animated animate__fadeIn animate__slower">
                <div className="account-box animate__animated animate__fadeInDown animate__slower">
                    <img src={`${process.env.PUBLIC_URL}/icons/bca-bank.png`} alt="bank" className="bank-icon"/>
                    <div className="account-details">
                        <p className="account-number">1772649481</p>
                        <p className="account-bank">BCA: Taqwa Rahmansyah</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GiftPage;

