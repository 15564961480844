import React from 'react';
import './css/GalleryPage.css'; // File CSS untuk gaya khusus halaman Quotes
import "animate.css"

const GalleryPage = () => {
    return (
        <div className="resepsi-page">
            <div className="frame">
                <img
                    src={`${process.env.PUBLIC_URL}/tl.webp`}
                    alt="frame"
                    className="frame-tl animate__animated animate__fadeInTopLeft animate__slow"
                />
                <img
                    src={`${process.env.PUBLIC_URL}/tr.webp`}
                    alt="frame"
                    className="frame-tr animate__animated animate__fadeInTopRight animate__slower"
                />
                <img
                    src={`${process.env.PUBLIC_URL}/bl.webp`}
                    alt="frame"
                    className="frame-bl animate__animated animate__fadeInBottomLeft animate__slower"
                />
                <img
                    src={`${process.env.PUBLIC_URL}/br.webp`}
                    alt="frame"
                    className="frame-br animate__animated animate__fadeInBottomRight animate__slower"
                />
            </div>
            <p className="gallery-text text-center">Our Gallery</p>
            <div className="gallery-container">
                <div className="gallery-item item1 animate__animated animate__zoomIn animate__slower">
                    <img src="https://firebasestorage.googleapis.com/v0/b/inviteku-app.appspot.com/o/photos%2Fsquare.png?alt=media&token=92ec369b-20a9-49af-b395-f996d87290ca" alt="Photo 1"/>
                </div>
                <div className="gallery-item item2 animate__animated animate__zoomIn animate__slower">
                    <img src="https://firebasestorage.googleapis.com/v0/b/inviteku-app.appspot.com/o/photos%2Ftaqwa-1.JPG?alt=media&token=8cb6c769-9271-4d88-8c07-f12ff210ddb8" alt="Photo 2"/>
                </div>
                <div className="gallery-item item3 animate__animated animate__zoomIn animate__slower">
                    <img src="https://firebasestorage.googleapis.com/v0/b/inviteku-app.appspot.com/o/photos%2Ftqw-1.JPG?alt=media&token=0b0682e3-f64b-4fb0-9919-3d02fc9a7df0" alt="Photo 3"/>
                </div>
                <div className="gallery-item item4 animate__animated animate__zoomIn animate__slower">
                    <img src="https://firebasestorage.googleapis.com/v0/b/inviteku-app.appspot.com/o/photos%2Ftaqwa-3.JPG?alt=media&token=f7169ae8-abd4-47f9-8498-2f788fae135f" alt="Photo 4"/>
                </div>
                <div className="gallery-item item5 animate__animated animate__zoomIn animate__slower">
                    <img src="https://firebasestorage.googleapis.com/v0/b/inviteku-app.appspot.com/o/photos%2Ftaqwa-2-square.JPG?alt=media&token=db82f76a-fc8f-47ae-ab23-126d08cd8572" alt="Photo 5"/>
                </div>
                {/*<div className="gallery-item item6">*/}
                {/*    <img src={`${process.env.PUBLIC_URL}/square.png`} alt="Photo 6" />*/}
                {/*</div>*/}
            </div>
        </div>
    );
};

export default GalleryPage;
