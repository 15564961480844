import React from 'react';
import './css/ResepsiPage.css'; // File CSS untuk gaya khusus halaman Quotes
import "animate.css"

const ResepsiPage = () => {
    return (
        <div className="resepsi-page">
            <div className="frame">
                <img
                    src={`${process.env.PUBLIC_URL}/tl.webp`}
                    alt="frame"
                    className="frame-tl animate__animated animate__fadeInTopLeft animate__slow"
                />
                <img
                    src={`${process.env.PUBLIC_URL}/tr.webp`}
                    alt="frame"
                    className="frame-tr animate__animated animate__fadeInTopRight animate__slower"
                />
                <img
                    src={`${process.env.PUBLIC_URL}/bl.webp`}
                    alt="frame"
                    className="frame-bl animate__animated animate__fadeInBottomLeft animate__slower"
                />
                <img
                    src={`${process.env.PUBLIC_URL}/br.webp`}
                    alt="frame"
                    className="frame-br animate__animated animate__fadeInBottomRight animate__slower"
                />
            </div>
            <div className="resepsi-container animate__animated animate__fadeIn animate__slower">
                <h1 className="resepsi-title animate__animated animate__fadeInDown animate__slower">Ngunduh Mantu</h1>
                <div className="resepsi-date animate__animated animate__zoomIn animate__slower">
                    <p className="resepsi-day">Selasa</p>
                    <div className="resepsi-day-number-container">
                        <hr className="line-above"/>
                        <span className="resepsi-day-number">25</span>
                        <hr className="line-below"/>
                    </div>
                    <p className="resepsi-month-year">Juni 2024</p>
                </div>
                <p className="resepsi-time animate__animated animate__fadeInUp animate__slower">Ngunduh Mantu Pukul 10.00 WIB - Selesai</p>
                <p className="resepsi-location-name animate__animated animate__fadeInUp animate__slower">Dusun Saradan,Desa Sugihwaras</p>
                <p className="resepsi-location-address animate__animated animate__fadeInUp animate__slower">Kecamatan Saradan, Madiun
                </p>
            </div>
        </div>
    );
};

export default ResepsiPage;
